
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderStatus } from '@/constant/order';
import { Toast } from 'antd-mobile';
import { logout, driverInfo } from '@/utils/api';
import { useNavigate } from 'react-router-dom';
import { CHANGE_ORDER_STATUS, HOT_AREA_TYPE, CarStateArr } from '@/store/action/type';
import Style from './index.module.less';
import Avtar from '@/assets/defaultAvata.png';
import close from "@/assets/close.png";
import DialogCustom from '@/components/common/AlertCommon';
import logSend from '@/utils/aLog';

const { IDEL, LISTEN_ORDER, FINISH_ORDER, PASSENGER_CANCEL, DRIVER_CANCEL } = OrderStatus;


/**
 * 用户信息页
 */
export default function UserInfo() {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    const OrderStatus = useSelector((state) => state.order.OrderStatus);
    const carState = useSelector(state => state.car.carState);
    const workStatus = CarStateArr.indexOf(carState)!=-1;

    const hasOrder = ![IDEL, LISTEN_ORDER, FINISH_ORDER, PASSENGER_CANCEL, DRIVER_CANCEL].includes(
        OrderStatus
    );
    const dispatch = useDispatch();
    const LoginOutRef = useRef(null);
    function handleChange() {
        if (workStatus) {
          Toast.show('您还在上班，不能退出登录');
          return Promise.reject();
        }
        if (hasOrder) {
          Toast.show('您还有正在服务的订单，不能退出登录');
          return Promise.reject();
        }
        logSend({
          action: 'loginOutClick',
        });
        return logout().then(() => {
          // 开发环境使用，用来断开robonode连接（只可以连接一个）
          // !isProd && close();
          hide();
          dispatch({
            type: CHANGE_ORDER_STATUS,
            data: IDEL,
          });
          logSend({
            action: 'loginOutSuccess',
          });
          localStorage?.removeItem("driver_token")
          navigate('/login');
        });
    }
    const hide = ()=>{
      dispatch({
        type: 'CHANGE_TAB',
        data: 'map',
      })
    }
    function IsLogout(){
      DialogCustom.show({
        msg: '确认要退出登录吗？',
        onOk: () => {
          handleChange()
        }
      });
    }
    useEffect(async () => {
      let res = null;
      try{
        res = await driverInfo();
        if(res.code == 0){
          setUserData(res?.data?.driver);
        }
      } catch(err) {
        // 兜底
        setUserData({
          name: '暂无数据',
          mtour: false
        });
      }
      return () => {};
    }, []);

    useEffect(()=>{
      const LoginOutBtnArea = LoginOutRef.current.getBoundingClientRect();
      const LoginOutBtnAreaInfo = `${LoginOutBtnArea.x}, ${LoginOutBtnArea.y}, ${LoginOutBtnArea.width}, ${LoginOutBtnArea.height}`;
      dispatch({
        type: HOT_AREA_TYPE.GoOn,
        data: LoginOutBtnAreaInfo
      });
      return () => {
        dispatch({
          type: HOT_AREA_TYPE.GoOn,
          data: ""
        });
      };
    })

    return (
        <div className={Style.Mask}>
          <div ref={LoginOutRef} className={Style.Container}>
            <div className={Style.Close} onClick={hide}>
              <img className={Style.CloseIcon}src={close} alt="" />
            </div>
            <div className={Style.UserInfo}>
                { 
                  userData?.mtour ? <span className={Style.crown}></span> : ''
                }
                <img className={Style.UserAvata} src={Avtar}></img>
                <p className={Style.UserText}>{userData?.name ||  '昵称'}</p>
                {
                  userData?.mtour ?  <div className={Style.Flag}>
                  Mtour金牌司机
                </div> : ''
                }
            </div>
            <div className={Style.LoginOut} onClick={IsLogout}>退出登录</div> 
        </div>
        </div>
    )
}