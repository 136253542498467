import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { orderList, cancelOrder } from '@/utils/api';
import { getOrgAndDstName } from "../getPassenger/fn";
import { HOT_AREA_TYPE } from '@/store/action/type';
import DialogCustom from '@/components/common/AlertCommon';
import { getAreaInfo, pre_time } from '@/utils/index';
import close from "@/assets/close.png";
import "./index.less";
import { Toast } from "antd-mobile";

export default function OrderList() {
    const menuType = useSelector((state) => state.slider.CurTab);
    const [list, setList] = useState([]);
    const dispatch = useDispatch();
    
    const hotList = useRef();

    const getOrderStatus = (type) => {
        switch(type) {
            case "TripState_TRIP_STATE_ACCEPT":
                return "预约成功";
        }
    }
    const hanlderClose = () => {
        dispatch({
            type: 'CHANGE_TAB',
            data: "map",
        });
    }

    const handleCancel = async (trip_id) => {
        let res = await cancelOrder({
            trip_id: trip_id,
            state: "TRIP_CANCEL_STATE_DRIVER_CANCEL"
        });
        if(res.code === 0){
            Toast.show("取消成功");
        }
        await getOrderList();
    }

    const getOrderList = async ()=> {
        let res = await orderList({
            "pagePager.page": 1,
            "pagePager.size": 100
        });
        if(res.code == 0){
            let tempData = res?.data?.items || []
            tempData = tempData.map(item => {
                let obj = {}
                obj.user_phone = item?.user_phone;
                obj.orgPointName = getOrgAndDstName(item?.trip_points)?.start_name;
                obj.dstPointName = getOrgAndDstName(item?.trip_points)?.end_name;
                obj.car_time = item?.trips?.car_time;
                obj.route_name = item?.route_name;
                obj.type = item?.trips?.trip_type;
                obj.trip_id = item?.trips?.trip_id;
                obj.state = item?.trips?.state;
                return obj;
            });
            setList(tempData)
        }
    }

    const IsCancel = (id) => {
        DialogCustom.show({
            msg: '请确认是否取消订单',
            onOk: () => {
                handleCancel(id);
            }
        });
    }
    
    useEffect(async ()=>{
        await getOrderList();
    }, [])

    useEffect(() => {
        dispatch({
            type: HOT_AREA_TYPE.OrderList, 
            data: getAreaInfo(hotList)
        });
        return ()=>{
            dispatch({
                type: HOT_AREA_TYPE.OrderList, 
                data: []
            });
        }
    }, [menuType]);

    return (<div className="order-list-mask">
        <div ref={hotList} className="order-list-wrapper">
            <div className="order-list-header">
                <div onClick={hanlderClose} className="order-close-wrap">
                    <img src={close} alt="关闭" />
                </div>
            </div>
            <ul className="order-list">
                { list.length == 0 ? <div className="order-empty">
                    没有预约单
                </div> : " "
                }
                { list.map((item, index)=> {
                    return <li key={index} className="order-item">
                            <div className="item-title">
                                <span className="order-type">{item.type == "TRIP_TYPE_MTOUR" ? "Mtour": "Robotaix"}</span>
                                <span className="order-status">{getOrderStatus(item?.state)}</span>
                            </div>
                            <div className="list-item">
                                <label>用车时间</label>
                                <span>{pre_time(item.car_time)}</span>
                            </div>
                            <div className="list-item">
                                <label>上下车点</label>
                                <span>{item.orgPointName}-{item.dstPointName}</span>
                            </div>
                            <div className="list-item">
                                <label>路线名称</label>
                                <span>{item.route_name}</span>
                            </div>
                            <div className="list-item">
                                <label>乘客电话</label>
                                <span>{item.user_phone}</span>
                            </div>
                            <div className="cancel-btn" onClick={ () => IsCancel(item?.trip_id) }>取消订单</div>
                        </li>
                    })
                }
            </ul>
        </div>
    </div>)
}