const { REACT_APP_ENV, NODE_ENV } = process.env;
export const isProd = REACT_APP_ENV === 'prod';
export const isBeta = REACT_APP_ENV === 'beta';
export const isDev = REACT_APP_ENV === 'dev';
export const isLocal = NODE_ENV === 'development';

let HOST, MVIZ_URL, ROBONODE_WSS;

if(isProd){
  HOST = 'http://api.robotaxi.momenta.cn';
} else if(isBeta){
  HOST = 'http://beta-api.robotaxi.momenta.cn'
} else if(isDev){
  HOST = 'http://dev-api.robotaxi.momenta.cn';
} else {
  // console.log('请正确添加接口前缀');
  HOST = 'http://dev-api.robotaxi.momenta.cn';
}

if(isProd || isBeta || isDev){
  MVIZ_URL = 'http://192.168.1.10:3001/dmi?source=hmi';
  ROBONODE_WSS = 'ws://192.168.1.10:5001';
} else {
  MVIZ_URL = 'http://192.168.30.43:3000';
  ROBONODE_WSS = 'ws://192.168.30.42:5001';
}

export {
  HOST, // 接口HOST
  MVIZ_URL, // Mviz地址
  ROBONODE_WSS, // Robonode websocket地址
  REACT_APP_ENV
};

// API接口conf
export const API = {
  CarList: '/v1/driver/carList',
  BindCar: '/v1/driver/bindCar',
  UnBindCar: '/v1/driver/unbindCar',
  CarFlow: '/v1/trip/carFlow',
  CarAccept: '/v1/trip/carAccept',
  CarCancel: '/v1/trip/carCancel',
  CarArrived: '/v1/trip/carArrived',
  InfoSync: '/v1/trip/eventInfoSync',
  RouteSync: '/v1/trip/drivingRouteSync',
  TripStart: '/v1/trip/tripStart',
  TripEnd: '/v1/trip/tripEnd',
  ContinueWork: '/v1/car/continueWork',
  PickupRoute: '/v1/trip/driverPickupRoute',
  DriverInfo: '/v1/driver/driverInfo',
  onDuty: '/v1/driver/onDuty',
  offDuty: '/v1/driver/offDuty',
  carList: 'v1/trip/carList', // 订单列表
  carStart: '/v1/trip/carStart', // 去接乘客
  orderList: '/v1/trip/carList',
  getCarNum: '/v1/car/carInfoByDeviceBoxId'
};
// 飞书机器人hook通知链接地址
export const FeiShuUrl =
  'https://open.feishu.cn/open-apis/bot/v2/hook/d194af86-9ed9-4dd0-99c5-454600793ffe';

export const PointCommon = {
  zooms: [10, 20],
  opacity: 1,
  zIndex: 1001,
}

export const PointIcon = {
  type: 'image',
  image: 'https://a.amap.com/jsapi_demos/static/images/poi-marker.png',
  size: [25, 34],
  anchor: 'bottom-center',
  angel: 0,
  retina: true
}

export const PointText = {
  direction: 'top',
  offset: [0, 0],
  style: {
      fontSize: 15,
      fontWeight: 'normal',
      fillColor: '#333',
      strokeColor: '#fff',
      strokeWidth: 2,
  }
}

export const RouteConfig = {
  success: {
      status: 'route-tips-success',
      spin: 'spinner-success',
      text: '智驾规划成功'
  },
  fail: {
      status: 'route-tips-fail',
      spin: 'spinner-fail',
      text: '智驾规划失败'
  },
  planning: {
      status: 'route-tips-doing',
      spin: 'spinner-doing',
      text: '智驾规划中...'
  },
}

export const MTOUR_TIMEOUT = 30;
export const ROBOTAXI_TIMEOUT = 5;

export const SliderData = [
  {
    name: '账户',
    IconFont: '\ue607',
    component: 'user'
  },
  // {
  //   name: '地图',
  //   IconFont: '\ue609',
  //   component: 'map'
  // },
  {
    name: '订单',
    IconFont: '\ue641',
    component: 'order'
  },
  // {
  //   name: '信息',
  //   IconFont: '\ue63e',
  //   component: 'info'
  // }
];

export const sliderFontSize = {
  'height': '0.22rem',
  'width': '0.3rem',
  'lineHeight': '0.25rem',
  'display': 'flex',
  'justifyContent': 'center',
}

export const initTabData = {
  type: 'CHANGE_TAB',
  data: 'map',
};